import React from "react";

const experienceContent = [
  {
    year: "   Audiovisual production",
   
  
    details: `  Institutional, commercial and music videos. Advertising spots.`,
  },
  {
    year: "Design",
    
  
    details: `Creation of advertising campaigns, logos, corporate image and creation of applications (promotional items). `,
  },
  {
    year: "Photography and Retouching",
    
   
    details: `Commercial, aerial, architectural, product, food, professional portrait, industrial, and event photography.    `,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-check-square-o"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
