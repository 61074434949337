import React from "react";

const experienceContent = [
  {
    year: "   Producción Audiovisual",
   
  
    details: `  Video institucional, spot publicitario, video comercial y videos musicales. `,
  },
  {
    year: "Diseño",
    
  
    details: `Creación de campañas publicitarias, logotipos, imagen corporativa y creación de aplicativos (artículos promocionales).`,
  },
  {
    year: "Fotografia y Retoque",
    
   
    details: `Fotografía comercial, aérea, arquitectónica, fotografía de producto, 
    de alimentos, retrato profesional, industrial, de eventos.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-check-square-o"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
