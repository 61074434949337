import React, { useState } from "react";
import {Tabs, TabList, TabPanel } from "react-tabs";
import Modal from "react-modal";
import ModalOneContent from "./modal/ModalOneContent";
import ModalTwoContent from "./modal/ModalTwoContent";
import ModalThreeContent from "./modal/ModalThreeContent";
import ModalFourContent from "./modal/ModalFourContent";
import ModalFiveContent from "./modal/ModalFiveContent";
import ModalSixContent from "./modal/ModalSixContent";
import ModalSevenContent from "./modal/ModalSevenContent";
import ModalEightContent from "./modal/ModalEightContent";
import ModalNineContent from "./modal/ModalNineContent"; import ModaltenContent from "./modal/ModaltenContent";
import ModalelevenContent from "./modal/ModalelevenContent";
import ModaltwelveContent from "./modal/ModaltwelveContent";
import ModalthirteenContent from "./modal/ModalthirteenContent";
import ModalfourteenContent from "./modal/ModalfourteenContent";
import ModalQuinceContent from "./modal/ModalQuinceContent";
import ModalDieciseisContent from "./modal/ModalDieciseisContent";
import ModalDiecisieteContent from "./modal/ModalDiecisieteContent";
import ModalDieciochoContent from "./modal/ModalDieciochoContent";
import ModalDiecinueveContent from "./modal/ModalDiecinueveContent";
import ModalVeinteContent from "./modal/ModalVeinteContent";
import ModalVeintiunoContent from "./modal/ModalVeintiunoContent";
import ModaltwentytwoContent from "./modal/ModaltwentytwoContent";
import ModalVeintitresContent from "./modal/ModalVeintitresContent";
import ModalVeinticuatroContent from "./modal/ModalVeinticuatroContent";


const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);  const [isOpen10, setIsOpen10] = useState(false);  const [isOpen11, setIsOpen11] = useState(false); const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen13, setIsOpen13] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);
  const [isOpen17, setIsOpen17] = useState(false);
  const [isOpen18, setIsOpen18] = useState(false);
  const [isOpen19, setIsOpen19] = useState(false);
  const [isOpen20, setIsOpen20] = useState(false);
  const [isOpen21, setIsOpen21] = useState(false);
  const [isOpen22, setIsOpen22] = useState(false);
  const [isOpen23, setIsOpen23] = useState(false);
  const [isOpen24, setIsOpen24] = useState(false);

  
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }
  function toggleModalSeven() {
    setIsOpen7(!isOpen7);
  }
  function toggleModalEight() {
    setIsOpen8(!isOpen8);
  }
  function toggleModalNine() {
    setIsOpen9(!isOpen9);
  }                             function toggleModalten() {
    setIsOpen10(!isOpen10);
  }
  function toggleModaleleven() {
    setIsOpen11(!isOpen11);
  }

  function toggleModaltwelve() {
    setIsOpen12(!isOpen12);
  }

  function toggleModalthirteen() {
    setIsOpen13(!isOpen13);
  }

  function toggleModalfourteen() {
    setIsOpen14(!isOpen14);
  }

  function toggleModalQuince() {
    setIsOpen15(!isOpen15);
  }

  function toggleModalDieciseis() {
    setIsOpen16(!isOpen16);
  }

  function toggleModalDiecisiete() {
    setIsOpen17(!isOpen17);
  }

  function toggleModalDieciocho() {
    setIsOpen18(!isOpen18);
  }
  

  function toggleModalDiecinueve() {
    setIsOpen19(!isOpen19);
  }

  function toggleModalVeinte() {
    setIsOpen20(!isOpen20);
  }

  function toggleModalVeintiuno() {
    setIsOpen21(!isOpen21);
  }


  function toggleModaltwentytwo() {
    setIsOpen22(!isOpen22);
  }

  function toggleModalVeintitres() {
    setIsOpen23(!isOpen23);
  }

  function toggleModalVeinticuatro() {
    setIsOpen24(!isOpen24);
  }

  
  

  return (


      <div className="portfolio-tab-content">

          <ul className="row grid justify-content-center">


           








{/* <!-- Reel 2021 --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalVeinticuatro}
              >
                <img src="img/projects/reel.webp" alt="Casa Molina 2021" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen24}
                onRequestClose={toggleModalVeinticuatro}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalVeinticuatro}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalVeinticuatroContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- BRP 15 --> */}







{/* <!-- BRP 15 --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalVeintitres}
              >
                <img src="img/projects/15brp.webp" alt="Casa Molina 2021" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen23}
                onRequestClose={toggleModalVeintitres}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalVeintitres}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalVeintitresContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- BRP 15 --> */}






{/* <!-- Casa Molina 2021 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalDiecinueve}
              >
                <img src="img/projects/casa.webp" alt="Casa Molina 2021" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"> </span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen19}
                onRequestClose={toggleModalDiecinueve}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalDiecinueve}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalDiecinueveContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- Casa Molina 2021 Ends --> */}
















            {/* <!-- Rosa de arena 2021 Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFive}
              >
                <img src="img/projects/rosadearena.jpg" alt="Rosa de Arena 2021" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen5}
                onRequestClose={toggleModalFive}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFive}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFiveContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- Rosa de Arena Ends --> */}












{/* <!-- Mangos 2020 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalVeinte}
              >
                <img src="img/projects/mangos.jpg" alt="Mangos" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen20}
                onRequestClose={toggleModalVeinte}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalVeinte}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalVeinteContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- Mangos 2020 Ends --> */}











{/* <!-- Gas Natural 2020 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalFour}
              >
                <img src="img/projects/gnn_projects.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalFourContent */}
              <Modal
                isOpen={isOpen4}
                onRequestClose={toggleModalFour}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalFour}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalFourContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFourContent */}
            </li>
            {/* <!-- Gas Natural 2020 Ends --> */}









{/* <!-- Cuenta Conmigo 2020 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalten}
              >
                <img src="img/projects/cuenta.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen10}
                onRequestClose={toggleModalten}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalten}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModaltenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Cuenta Conmigo 2020 Ends --> */}












{/* <!-- Saludyvida 2020 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalDieciseis}
              >
                <img src="img/projects/saludyvida.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen16}
                onRequestClose={toggleModalDieciseis}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalDieciseis}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalDieciseisContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Saludyvida 2020 Starts Ends --> */}












{/* <!-- BRP Adventure 2020 Starts --> */}
<li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure onClick={toggleModalThree}>
                <img src="img/projects/brp-project.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalThreeContent */}
              <Modal
                isOpen={isOpen3}
                onRequestClose={toggleModalThree}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalThree}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalThreeContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalThreeContent */}
            </li>
            {/* <!-- BRP Adventure 2020 Ends --> */}













            {/* <!-- Por ti por ellos 2020 Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModaleleven}
              >
                <img src="img/projects/por-ellos.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen11}
                onRequestClose={toggleModaleleven}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModaleleven}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalelevenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Por ti por ellos 2020 Ends --> */}

















{/* <!-- Viva 2020 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalDiecisiete}
              >
                <img src="img/projects/viva01.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen17}
                onRequestClose={toggleModalDiecisiete}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalDiecisiete}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalDiecisieteContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Viva 2020 Ends --> */}













            {/* <!-- Sirena 2020 Starts --> */}
              <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalDieciocho}
              >
                <img src="img/projects/sirena01.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen18}
                onRequestClose={toggleModalDieciocho}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalDieciocho}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalDieciochoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Sirena 2020 Ends --> */}















              {/* <!-- Pagano 2019 Starts --> */}
              {/* Start ModalOneContent */}
              <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure onClick={toggleModalOne}>
                <img src="img/projects/project-1.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalOne}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalOneContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneContent */}
            </li>
            {/* <!-- Pagano 2019 Ends --> */}









            {/* <!-- Rotario 2019 Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalEight}
              >
                <img src="img/projects/rotario.jpg" alt="Portolio" />

                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>
              {/* Start ModalEightContent */}
              <Modal
                isOpen={isOpen8}
                onRequestClose={toggleModalEight}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalEight}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalEightContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalEightContent */}
            </li>
            {/* <!-- Rotario 2019  Ends --> */}










            {/* <!-- Alavista 2019 Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSix}
              >
                <img src="img/projects/ala.webp" alt="Alavista" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalSixContent */}
              <Modal
                isOpen={isOpen6}
                onRequestClose={toggleModalSix}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSix}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSixContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSixContent */}
            </li>
            {/* <!-- Alavista 2019 Ends --> */}














{/* <!-- Stella 2019 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModaltwelve}
              >
                <img src="img/projects/estela.webp" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen12}
                onRequestClose={toggleModaltwelve}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModaltwelve}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModaltwelveContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Stella 2019 Ends --> */}








            {/* <!-- Formando 2018 Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalSeven}
              >
                <img src="img/projects/formando.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalSevenContent */}
              <Modal
                isOpen={isOpen7}
                onRequestClose={toggleModalSeven}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalSeven}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalSevenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalSevenContent */}
            </li>
            {/* <!-- Formando 2018 Ends --> */}












{/* <!-- Ruba 2018 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalVeintiuno}
              >
                <img src="img/projects/ruba.jpg" alt="Casa Molina 2021" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalFiveContent */}
              <Modal
                isOpen={isOpen21}
                onRequestClose={toggleModalVeintiuno}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalVeintiuno}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalVeintiunoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalFiveContent */}
            </li>
            {/* <!-- Ruba 2018 Ends --> */}










{/* <!-- InPrint 2018 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalthirteen}
              >
                <img src="img/projects/inprint.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen13}
                onRequestClose={toggleModalthirteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalthirteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalthirteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- InPrint 2018 Ends --> */}




















{/* <!-- Ethicon 2018 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalfourteen}
              >
                <img src="img/projects/ethicon.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen14}
                onRequestClose={toggleModalfourteen}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalfourteen}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalfourteenContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Ethicon 2018 Ends --> */}

















            {/* <!-- Charly 2018 Starts --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <figure onClick={toggleModalTwo}>
                <img src="img/projects/project-2.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalTwoContent */}
              <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModalTwo}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalTwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalTwoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- Charly 2018 Ends --> */}

















            {/* <!-- Monarch 2017 Starts --> */}
            <li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalNine}
              >
                <img src="img/projects/monarch.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen9}
                onRequestClose={toggleModalNine}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalNine}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalNineContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Monarch 2017 Ends --> */}








            





{/* <!-- GNN Evolution 2017 Starts --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModalQuince}
              >
                <img src="img/projects/gnnev.jpg" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen15}
                onRequestClose={toggleModalQuince}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModalQuince}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModalQuinceContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- GNN Evolution 2017 Ends --> */}
















{/* <!-- Curves --> */}
<li
              className="direction-reveal"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <figure
                className="direction-reveal__card"
                onClick={toggleModaltwentytwo}
              >
                <img src="img/projects/curves.webp" alt="Portolio" />
                <div className=" hover-content-wrapper">
                  <span className="content-title"></span>
                </div>
              </figure>

              {/* Start ModalNineContent */}
              <Modal
                isOpen={isOpen22}
                onRequestClose={toggleModaltwentytwo}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={toggleModaltwentytwo}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner portfolio">
                    <ModaltwentytwoContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalNineContent */}
            </li>
            {/* <!-- Curves --> */}










            

          </ul>

          

        {/* End All Project */}

        
      </div>





















      

  );
};

export default Portfolio;
