import React, { useState } from "react";
import Modal from "react-modal";
import Index from "../../components/about/index";

const heroContent = {
  heroImage: "img/hero/dark.jpg",
  heroMobileImage: "img-mobile",
  heroTitleName: "Somos",
  heroDesignation: "Visión Hybrida",
  heroDescriptions: `Una agencia de Publicidad fundada en el 2008. 
  Iniciamos como casa productora especializada en el desarrollo de comerciales
  y videos corporativos en Ciudad Juárez. A través de los años hemos expandido 
  nuestro portafolio de servicios integrando: Fotografía comercial, multimedia,
  desarrollo de contenidos para medios audiovisuales, postproducción, diseño 
  gráfico, impresión, entre otros.`,
  heroBtn: "English site version",
};

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
            <img
              src={`img/hero/${heroContent.heroMobileImage}.jpg`}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
              {heroContent.heroTitleName}
              <span>{heroContent.heroDesignation}</span>
            </h1>
            <p className="open-sans-font">{heroContent.heroDescriptions}</p>
            
            




            
            
            <a href="/home-light">
              <button className="button" onClick={toggleModalOne}>
              <span className="button-text">{heroContent.heroBtn}</span>
              <span className="button-icon fa fa-arrow-right"></span>
            </button></a>








          </div>
        </div>
      </div>
      {/* End home-details-container */}

 
    </>
  );
};

export default Hero;
