import React, { useState } from "react";
import Modal from "react-modal";
import Index from "../about/index";

const heroContent = {
  heroImage: "img/hero/light.jpg",
  heroMobileImage: "img-mobile-light",
  heroTitleName: "We are",
  heroDesignation: "Vision Hybrida",
  heroDescriptions: `Advertising Agency founded in 2008.
  We started as a production house specializing in the development of commercials
  and corporate videos in Ciudad Juárez. Over the years it has expanded its
  portfolio of services integrating: commercial photography, multimedia, development of
  TV shows, post-production for movies, graphic design, printing, among others.`,
  heroBtn: "Versión en Español",
};

const HeroLight = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
            <img
              src={`img/hero/${heroContent.heroMobileImage}.jpg`}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
             {heroContent.heroTitleName}
              <span>{heroContent.heroDesignation}</span>
            </h1>
            <p className="open-sans-font">{heroContent.heroDescriptions}</p>



            <a href="/">
              <button className="button" onClick={toggleModalOne}>
              <span className="button-text">{heroContent.heroBtn}</span>
              <span className="button-icon fa fa-arrow-right"></span>
            </button></a>





          </div>
        </div>
      </div>
      {/* End home-details-container */}

      {/* Start Modal for About More */}
     
      {/* End  Modal for About More */}
    </>
  );
};

export default HeroLight;
