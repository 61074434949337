import React, { useState } from "react";
import Modal from "react-modal";

import ModalOneBlogContent from "./modal/ModalOneBlogContent";
import ModalTwoBlogContent from "./modal/ModalTwoBlogContent";
import ModalThreeBlogContent from "./modal/ModalThreeBlogContent";
import ModalFourBlogContent from "./modal/ModalFourBlogContent";
import ModalFiveBlogContent from "./modal/ModalFiveBlogContent";
import ModalSixBlogContent from "./modal/ModalSixBlogContent";
import ModalSevenBlogContent from "./modal/ModalSevenBlogContent";
import ModalEightBlogContent from "./modal/ModalEightBlogContent";
import ModalNineBlogContent from "./modal/ModalNineBlogContent";
import ModalTenBlogContent from "./modal/ModalTenBlogContent";
import ModalOnceBlogContent from "./modal/ModalOnceBlogContent";
import ModalDoceBlogContent from "./modal/ModalDoceBlogContent";
import ModalCatorceBlogContent from "./modal/ModalCatorceBlogContent";
import ModalQuinceBlogContent from "./modal/ModalQuinceBlogContent";
import ModalDieciseisBlogContent from "./modal/ModalDieciseisBlogContent";
import ModalDiecisieteBlogContent from "./modal/ModalDiecisieteBlogContent";
import ModalDieciochoBlogContent from "./modal/ModalDieciochoBlogContent";
import ModalDiecinueveBlogContent from "./modal/ModalDiecinueveBlogContent";
import ModalVeinteBlogContent from "./modal/ModalVeinteBlogContent";
import ModalVeintiunoBlogContent from "./modal/ModalVeintiunoBlogContent";

Modal.setAppElement("#root");

const Blog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  const [isOpen11, setIsOpen11] = useState(false);
  const [isOpen12, setIsOpen12] = useState(false);
  const [isOpen14, setIsOpen14] = useState(false);
  const [isOpen15, setIsOpen15] = useState(false);
  const [isOpen16, setIsOpen16] = useState(false);
  const [isOpen17, setIsOpen17] = useState(false);
  const [isOpen18, setIsOpen18] = useState(false);
  const [isOpen19, setIsOpen19] = useState(false);
  const [isOpen20, setIsOpen20] = useState(false);
  const [isOpen21, setIsOpen21] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }
  function toggleModalSix() {
    setIsOpen6(!isOpen6);
  }

  function toggleModalSeven() {
    setIsOpen7(!isOpen7);
  }
  
  function toggleModalEight() {
    setIsOpen8(!isOpen8);
  }


  function toggleModalNine() {
    setIsOpen9(!isOpen9);
  }

  function toggleModalTen() {
    setIsOpen10(!isOpen10);
  }

  function toggleModalOnce() {
    setIsOpen11(!isOpen11);
  }

  function toggleModalDoce() {
    setIsOpen12(!isOpen12);
  }

    function toggleModalCatorce() {
    setIsOpen14(!isOpen14);
  }

  function toggleModalQuince() {
    setIsOpen15(!isOpen15);
  }


  function toggleModalDieciseis() {
    setIsOpen16(!isOpen16);
  }

  function toggleModalDiecisiete() {
    setIsOpen17(!isOpen17);
  }


  function toggleModalDieciocho() {
    setIsOpen18(!isOpen18);
  }

  function toggleModalDiecinueve() {
    setIsOpen19(!isOpen19);
  }

  function toggleModalVeinte() {
    setIsOpen20(!isOpen20);
  }

  function toggleModalVeintiuno() {
    setIsOpen21(!isOpen21);
  }




  return (
    <>
      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.jnjmexico.com/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/JyJ.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}










      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://brp.com/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/brp.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}











      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.gasnn.com/juarez/index.php/es/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/gnn.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}










      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.alavista-signs.com/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/alavista.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}









      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="http://saludyvidapa.com/syv/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/saludyvida.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}






            {/*  Blog 3 Starts */}
            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://fundaciongrupoimperial.org/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/fgi.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}














      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.facebook.com/Casamolinajuarez" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/projects/casa.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}







      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.facebook.com/FruteriaMangos" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/projects/mangos.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}















      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://cordis.com/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/cordis.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}














      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.gcc.com/es/inicio/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/cementos.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}












      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.gcc.com/es/inicio/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/projects/ruba.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}













      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.pimsaseguros.com/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/pimsa.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}











      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://fechac.org.mx/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/fechac.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}














      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="http://www.coprofusa.com.mx/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/coprofusa.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}












      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.amesburytruth.com/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/amesbury.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}















      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://tecma.org.mx/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/tecma.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}
















      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.hrc.mx/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/enganiaralservidor.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}
















      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.larodadora.org/" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/rodadora.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}












      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.facebook.com/Joyeria-meza-custodia-1714533801932652" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/meza.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}













      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.lexmark.com/es_mx.html" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/blog/lexmark.webp"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}









      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.facebook.com/VivaMexicoJrz" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/projects/viva01.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}









      {/*  Blog 3 Starts */}
      <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30">
        <article className="post-container" onClick={toggleModalThree}>
          <div className="post-thumb">
            
          <a href="https://www.facebook.com/Sirena-Morena-2290360177670888" target="_blank">
            <div className="d-block position-relative overflow-hidden">
              <img
                src="img/projects/sirena01.jpg"
                
                className="img-fluid"
                alt="Blog Post"
              />
            </div> </a>
          </div>
          {/* End .thumb */}

          {/* End .post-content */}
        </article>

        {/* Start ModalThreeBlogContent */}
       
        {/* End  ModalThreeBlogContent */}
      </div>
      {/* Blog 3 Ends */}


























    </>
  );
};

export default Blog;
