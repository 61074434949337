import React from "react";

const Address = () => {
  return (
    <>
      
      {/* End .custom-span-contact */}
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-whatsapp position-absolute"></i>
        <span className="d-block">Enviar un Mensaje de whatsapp</span>{" "}
        <a href="https://web.whatsapp.com/send?phone=5216561677023&amp;text=Me%20interesa%20recibir%20una%20cotización,%20mi%20nombre%20es" target="_blank" class="btn btn-primary primary-typo">656-167-7023</a>
      </p>

      
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">Enviar un E-Mail</span>{" "}
        <a href="mailto:contacto@visionhybrida.com?Subject=Me%20gustaría%20recibir%20una%20cotización" class="btn btn-primary primary-typo">contacto@visionhybrida.com</a>
      </p>


      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-money position-absolute"></i>
        <span className="d-block">Cotizador (próximamente)</span>{" "}
        <a href="#" class="btn btn-primary primary-typo">Quiero una cotización personalizada</a>
      </p>
      {/* End .custom-span-contact */}

    
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
