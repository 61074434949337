import React from "react";

const personalInfoContent = [
  { metaInfo: "VISIÓN HYBRIDA" },
  
  { meta: "TIEMPO EN ACTIVIDAD:", metaInfo: "14 Años" },
  { meta: "UBICACIÓN:", metaInfo: "México" },
  
  { meta: "CIUDAD:", metaInfo: "Juárez Chihuahua" },
  { meta: "TELÉFONO:", metaInfo: "+52-656-167-7023" },
  { meta: "EMAIL:", metaInfo: "contacto@visionhybrida.com" },
  
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta} </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
