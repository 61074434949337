import React from "react";

const ModalTwoContent = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Por ellos, por ti, por juárez</h3>
          <div className="row open-sans-font">
            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-file-text-o pr-2"></i>
              <span className="project-label">Project</span>:{" "}
              <span className="ft-wt-600 uppercase">TV Spot</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-user-o pr-2"></i>
              <span className="project-label">Client </span>:{" "}
              <span className="ft-wt-600 uppercase">Fundación Grupo Imperial</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-calendar-check-o"></i>
              <span className="project-label"> Year </span>:{" "}
              <span className="ft-wt-600 uppercase">2020</span>
            </div>
            {/* End .col */}

            <div className="col-12 col-sm-6 mb-2">
              <i className="fa fa-external-link pr-2"></i>
              <span className="project-label">Visit external link </span>:{" "}
              <span className="ft-wt-600 uppercase">
                <a
                  href="https://youtu.be/pwqaQdRcuXA"
                  target="_blank"
                  rel="noreferrer"
                >
                  Youtube
                </a>
              </span>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}

        {/*  Main Project Content Starts */}
        <div className="videocontainer">
          <iframe
            className="youtube-video"
            title="youtube video"
            src="https://www.youtube.com/embed/pwqaQdRcuXA?controls=1" title="YouTube video player"
            allowFullScreen
          ></iframe>
        </div>
        {/* Main Project Content Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalTwoContent;
